@import "../../data/styles.css";

.works-body {
	margin-bottom: 30px;
	/* padding-bottom: 20px; */
}

.work {
	display: flex;
	/* padding-bottom: 40px; */
	width: 400px;
	align-items: center;
}

.works {
	margin-bottom: 50px;
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 16px;
	font-weight: bolder;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	/* position: absolute; */
	font-size: 14px;
	font-weight: bold;
	color: var(--secondary-color);
	/* padding-top: 22px; */
	padding-left: 50px;
}

.work-duration {
	/* position: absolute; */
	width: 350px;
	font-size: 14px;
	font-weight: 500;

	/* padding-top: 20px; */
	text-align: right;
	color: var(--secondary-color);
	padding-right: 30px;
}

.work-details-container {
	width: 940px;
	display: flex;
	justify-content: space-between;
}

.work-summary {
	padding-top: 5px;
	/* padding-left: 20px; */
	padding-right: 30px;
	color: var(--secondary-color);
}

@media (320px <= width <= 768px) {
	div.work {
		padding-bottom: 30px;
	}

	.works-body {
		font-size: 18px;
	}

	.work-image {
		height: 35px;
		width: 35px;
	}

	.work-title,
	.work-subtitle,
	.work-duration {
		font-size: 18px;
	}

	.work-summary {
		padding-right: 20px;
		ul {
			padding-inline-start: 20px;
		}

		li {
			padding-bottom: 10px;
		}
	}
}

@media (320px <= width <= 1600px) {
	div.work {
		display: flex;
		align-items: end;
		padding-bottom: 20px;
		width: 100%;
	}

	div.works {
		margin-bottom: 10px;
	}

	div.work-details-container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	div.work-subtitle {
		padding-left: 0px;
		word-wrap: break-word;
		padding-bottom: 10px;
	}

	div.work-duration {
		width: 100%;
		text-align: left;
	}
}
