/* .switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #090909;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: #f7f7f7;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #aeaeae;
}

input:focus + .slider {
	box-shadow: 0 0 1px #aeaeae;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
} */

div.theme-icons {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
	height: 4em;
	width: 4em;
	border-radius: 50%;
	background: var(--nav-bg-color);
}

.theme-icons svg {
	position: absolute;
	display: block;
}

.theme-icon {
	font-size: 2.5em;
	/* color: var(--primary-color); */
}

/* .icon-default {
	transition: opacity 0.3s, transform 0s;
} */

.icon-hover {
	/* transition: opacity 0.3s, transform 0s; */
	transform: scale(0.5);
	opacity: 0;
}

#moon-icon {
	color: #ccc7ff;
}
#sun-icon {
	/* color: ; */
	color: #f0c114;
}

/* .theme-icons:hover .icon-hover {
	transform: scale(1);
	opacity: 1;
}

.theme-icons:hover .icon-default {
	transform: scale(0.5);
	opacity: 0;
} */

.theme-icons:hover {
	background: var(--card-hover-color);
}

@media (min-width: 1600px) {
	div.theme-icons {
		height: 2.5em;
		width: 2.5em;
	}

	.theme-icon {
		font-size: 1.5em;
	}
}
