@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	/* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); */
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.project a {
	text-decoration: none;
}

.project:hover {
	/* background: #f7f7f7; */
	background: var(--card-hover-color);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;
	font-family: var(--tertiary-font);
}

.project-logo {
	width: 30px;
}

.project-logo img {
	width: 100%;
}

.project-title {
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
	margin-bottom: 2em;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text {
	padding-left: 20px;
	font-weight: 700;
}

.tech-name {
	margin-right: 5px;
	border-radius: 10px;
	background: var(--card-hover-color);
}

@media (320px <= width <= 768px) {
	.project {
		display: flex;
		flex-direction: column;
	}

	div.project-link {
		display: flex;
		justify-content: center;
		padding-top: 25px;
		height: max-content;
	}

	.project-link-icon {
		padding-left: 0px;
		padding-right: 5px;
	}

	div.project-link-text {
		padding-left: 5px;
		font-family: var(--primary-font);
		letter-spacing: normal;
	}

	div.project-logo {
		display: flex;
		justify-content: center;
	}
}

@media (414px < width <= 768px) {
	.project-title,
	.project-description {
		font-size: 19px;
	}

	.project-link {
		font-size: 16px;
	}
}
