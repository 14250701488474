@import "../../../data/styles.css";

.footer {
	display: flex;
	padding-top: 35px;
	/* margin-top: 20px; */
	padding-bottom: 35px;
	/* align-items: center; */
	justify-content: space-evenly;
	font-family: var(--primary-font);
}

ul.footer-nav-link-list {
	padding-left: 0;
}

.footer-links,
.footer-credits {
	flex-basis: 40%;
}

/* .footer-links {
	margin-left: -40px;
} */

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 85%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}

@media (max-width: 600px) {
	.footer {
		flex-direction: column;
		height: 85px;
	}

	.footer-links {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.footer-nav-link-list {
		width: 100%;
	}
}
