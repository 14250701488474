@import "../../../data/styles.css";

.card {
	mix-blend-mode: normal;
	border-radius: 20px;
	/* outline: 1px solid #f4f4f5; */
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.card-container {
	padding: 30px;
	padding-bottom: 5px;
}

.card-header {
	display: flex;
	align-items: center;
}

.card-icon {
	font-size: 25px;
	color: var(--secondary-color);
}

.card-title {
	color: var(--secondary-color);
	font-size: 20px;
	padding-left: 20px;
	font-weight: 650;
	font-family: var(--secondary-font);
}

.card-body {
	padding-top: 30px;
}

@media (414px < width <= 768px) {
	.card-title {
		font-size: 25px;
	}

	.card-icon {
		font-size: 30px;
	}

	.card-body {
		padding-top: 35px;
	}
}
