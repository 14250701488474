.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	margin-top: 120px;
}

.projects-list {
	margin-bottom: 50px;
	margin-top: 50px;
}

@media (320px <= width <= 768px) {
	.projects-logo {
		display: none;
	}

	div.projects-title {
		width: 100%;
		text-align: center;
		padding-top: 20px;
	}

	div.projects-subtitle {
		width: 100%;
		text-align: center;
		margin-top: 30px;
	}

	div.all-projects-container {
		display: flex;
		flex-direction: column;
	}

	div.all-projects-project {
		width: 100%;
		text-align: center;
	}

	div.projects-list {
		margin-top: 30px;
		margin-bottom: 0px;
	}

	div.projects-container {
		padding-top: 0px;
		margin-top: 90px;
	}
}

@media (414px < width <= 768px) {
	div.projects-title {
		font-size: 55px;
	}

	div.projects-subtitle {
		font-size: 19px;
	}
}

@media (768px <= width <= 1600px) {
	.projects-logo {
		display: none;
	}
}
