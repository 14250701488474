@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85%;
}

.homepage-subtitle {
	width: 85%;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 370px;
}

.homepage-image-wrapper {
	overflow: auto;
	border-radius: 10%;
	transform: rotate(3deg);
}

.homepage-image-wrapper img {
	width: 100%;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	/* padding-top: 40px; */
	/* padding-top: 15px; */
	padding-bottom: 0px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	/* flex-basis: 600px; */
	flex-basis: 1000px;
	padding-top: 70px;
	padding-bottom: 10px;
}

.all-home-cards-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 120px;
	/* padding-bottom: 30px; */
	margin-left: -35px;
	margin-right: -35px;
	justify-content: space-around;
	margin-bottom: 120px;
	font-family: var(--tertiary-font);
}

@media (320px <= width <= 768px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
		justify-content: center;
	}

	.homepage-image-container {
		margin-top: 90px;
		/* padding-bottom: 50px; */
		margin-left: 10px;
		order: 1;
	}

	div.homepage-title {
		width: 100%;
		order: 2;
		text-align: center;
		margin-top: 20px;
	}

	div.homepage-subtitle {
		width: 100%;
		order: 3;
		text-align: center;
		padding-top: 0px;
		margin-top: 35px;
	}

	.homepage-socials {
		display: flex;
		width: 100%;
		justify-content: space-around;
		padding-top: 0px;
		margin-top: 70px;
		order: 4;
	}

	svg.homepage-social-icon {
		font-size: 2.8em;
		padding-right: 0px;
		padding-left: 7px;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-logo-container {
		display: none;
	}

	div.all-home-cards-container {
		flex-basis: auto;
		margin-top: 70px;
		margin-bottom: 50px;
	}
}

@media (414px < width <= 768px) {
	div.homepage-title {
		font-size: 55px;
	}

	div.homepage-subtitle {
		font-size: 19px;
	}

	svg.homepage-social-icon {
		font-size: 3em;
	}

	div.all-home-cards-container {
		margin-top: 90px;
	}
}

@media (600px < width <= 768px) {
	div.homepage-title {
		margin-top: 40px;
	}

	div.homepage-image-container {
		margin-top: 120px;
	}
}

@media (768px < width <= 800px) {
	div.homepage-first-area-left-side {
		margin-right: 50px;
	}
}

@media (800px < width <= 1024px) {
	div.homepage-first-area-left-side {
		margin-right: 15px;
	}

	/* div.homepage-first-area-right-side {
		margin-right: 30px;
	} */
}

@media (max-width: 1024px) {
	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
