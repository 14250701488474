.home-card {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	/* outline: 1px solid #f4f4f5; */
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.home-card a {
	text-decoration: none;
}

/* .home-card-container {
	padding: 30px;
} */

.home-card-icon {
	font-size: 40px;
	/* color: #27272a; */
	color: var(--secondary-color);
}

.home-card-title {
	font-family: var(--primary-font);
	color: var(--primary-color);
	font-size: 20px;
	padding-top: 15px;
	padding-bottom: 1em;
	font-weight: 700;
}

.home-card-description {
	color: var(--secondary-color);
	padding-bottom: 1em;
	display: flex;
	font-size: 18px;
}

.home-card-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.home-card-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.home-card-link-text {
	padding-left: 20px;
	font-weight: 700;
}

.home-card-container {
	width: 350px;
	box-sizing: border-box;
	padding: 40px;
}

.home-card:hover {
	background: var(--card-hover-color);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.home-card:hover .home-card-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.home-title-icon-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-bottom: 1.5em;
}

@media (320px <= width <= 600px) {
	.home-card {
		height: 250px;
		width: 300px;
		margin-bottom: 30px;
	}

	div.home-card-container {
		width: 300px;
	}

	div.home-title-icon-container {
		display: flex;
		align-items: center;
	}
}

@media (414px < width <= 600px) {
	.home-card {
		height: 250px;
		width: calc(100vw - 100px);
		margin-bottom: 30px;
	}

	div.home-card-container {
		width: calc(100vw - 100px);
	}

	.home-card-title {
		font-size: 20px;
	}

	.home-card-description {
		font-size: 20px;
	}
}

@media (600px < width) {
}
